<template>
    <section>
        <section class="nav-container m-t-18">
            <a @click="navActiveKey = item.key" :class="item.key == navActiveKey ? 'active' : ''" v-for="item in navMap" :key="item.key">{{item.name}}</a>
        </section>

        <section>
            <level v-if="navActiveKey === 1" />
        </section>
    </section>
</template>

<script>
import Level from './components/level'
export default {
    data(){
        return{
            navMap:[
                {name:'问题等级',key:1}
            ],
            navActiveKey:1
        }
    },
    components:{Level}
}
</script>